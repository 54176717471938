import { Chat, Telegram, Twitter } from '@material-ui/icons'
import './About.css'

const About = () => (
    <div className='about center'>
      <h1>
        <img src="/assets/img/hccfactory.png" alt="HCC Factory" width="250" style={{verticalAlign: "middle"}}/>
      </h1>

      <h2 className='about__role'>dedicated to building on Terra Classic</h2>
      <p className='about__desc'>
        We have been here from the get go and have been building on LUNC since then. Join us on our mission to revive LUNC and make it the best it can be.
      </p>

      <div className='about__contact center'>
        <a href="http://t.me/HappyCattyCrypto"
          aria-label='telegram'
          className='link link--icon'
        >
          <Telegram />
        </a>
        
        <a href="https://www.twitter.com/HappyCatKripto"
          aria-label='twitter'
          className='link link--icon'
        >
          <Twitter />
        </a>

        <a href="https://discord.gg/HappyCattyCrypto#2521"
          aria-label='discord'
          className='link link--icon'
        >
          <Chat />
        </a>
      </div>
    </div>
  )

export default About
