import { Tooltip } from "@mui/material"

const EggCard = ({egg, handleHatch, handleEat, hatchFees}) => {
    const fee = (hatchFees && egg && hatchFees[egg.rarity.toLowerCase()]) ? (hatchFees[egg.rarity.toLowerCase()]) : 0
    const imgnum = (egg.id % 9) + 1;
    return (
        <div className='nft-card'>
            <div className='nft-card__image'>
                <img src={`/assets/img/egg-${egg.rarity}-${imgnum}.png`} alt={`${egg.rarity} Egg`} />
            </div>
            <div className='nft-card__content centered'>
                <h3 className='nft-card__title'>{`${egg.rarity} Egg`}</h3>
                <button type="button" className="btn btn--outline" onClick={() => { handleHatch(egg.id, fee)}}>Hatch Egg<br /><small>({fee ? (fee / 1000000).toLocaleString() : '???'} LUNC)</small></button>
                <Tooltip title="You can eat an egg if you don't want to hatch/open it. This is the equivalent to burning. It is gone forever afterwards.">
                    <button type="button" className="btn btn--outline btn--red" onClick={() => { handleEat(egg.id)}}>Eat (burn) Egg<br /><small>(you really want that?)</small></button>
                </Tooltip>
            </div>
        </div>
    )
}

export default EggCard