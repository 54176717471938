import { useContext, useState, useEffect } from 'react'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeContext } from '../../contexts/theme'
import { projects } from '../../portfolio'
import './Navbar.css'
import ProjectNav from './ProjectNav'
import ConnectWallet from '../ConnectWallet/ConnectWallet.tsx'

const Navbar = () => {
  const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)
  const [clickCount, setClickCount] = useState(0)
  const [showModal, setShowModal] = useState(false) // new state

  const toggleNavList = () => setShowNavList(!showNavList)

  const doToggleTheme = () => {
    toggleTheme()
    setClickCount(prevCount => {
      if (window.innerWidth > 1000) {
        console.log(prevCount + 1)
        return prevCount + 1;
      }
    })
  }

  useEffect(() => {
    if (clickCount === 3) {
      setShowModal(true) // open modal layer instead of a new window
      setClickCount(0)
    }

    const timer = setTimeout(() => {
      setClickCount(0)
    }, 1000)

    return () => clearTimeout(timer)
  }, [clickCount])

  return (
    <>
      <nav className='center nav'>
        <ul
          style={{ display: showNavList ? 'flex' : null }}
          className='nav__list'
        >
          <ProjectNav projects={projects} toggleNavList={toggleNavList} />
          <ConnectWallet />
        </ul>

        <button
          type='button'
          onClick={doToggleTheme}
          className='btn btn--icon nav__theme'
          aria-label='toggle theme'
        >
          {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
        </button>

        <button
          type='button'
          onClick={toggleNavList}
          className='btn btn--icon nav__hamburger'
          aria-label='toggle navigation'
        >
          {showNavList ? <CloseIcon /> : <MenuIcon />}
        </button>
      </nav>
      
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button 
              type="button" 
              onClick={() => setShowModal(false)} 
              className="btn btn--icon modal-close"
              aria-label="close modal"
            >
              &#x2715;
            </button>
            <iframe src="/easteregg.html" title="EasterEgg" style={{ width: '100%', height: '100%' }} />
          </div>
        </div>
      )}
    </>
  )
}

export default Navbar
